import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from "./shared/blocks/layout";
import Home from "./pages/home";
import Football from "./pages/football";
import Gymnastics from "./pages/gymnastics";
import Kudo from "./pages/kudo";
import PhysicalTraining from "./pages/physical-training";
import Volleyball from "./pages/volleyball";

import "swiper/css";
import "./App.scss";
import JiuJitsu from "./pages/bjj";

function App() {
    return (
        <React.Fragment>
            <Router>
                <Layout>
                    <Routes>
                        <Route path={'/'} element={<Home />}/>
                        <Route path={'/football' } element={<Football/>}/>
                        {/*<Route path={'/gymnastics'} element={<Gymnastics/>}/>*/}
                        <Route path={'/kudo'} element={<Kudo/>}/>
                        <Route path={'/jiu-jitsu'} element={<JiuJitsu/>}/>
                        <Route path={'/physical-training'} element={<PhysicalTraining/>}/>
                        <Route path={'/volleyball'} element={<Volleyball/>}/>
                    </Routes>
                </Layout>
            </Router>
        </React.Fragment>
    );
}

export default App;
