import classNames from "classnames";
import {useState} from "react";
import {useMediaQuery} from "react-responsive";
import {motion} from "framer-motion";
import Menu from "./components/menu";
import Title from "../../../../shared/components/title";
import Table from "../../../../shared/components/table";
import Carousel from "./components/carousel";

import styles from "./schedule.module.scss";

function Schedule() {
    const isMediumScreen = useMediaQuery({ query: '(max-width: 960px)' });
    const [state, setState] = useState('kudo');

    function handleSelect(value) {
        setState(value)
    }

    return (
        <section className={classNames(styles.schedule)} id={'schedule'}>
            <motion.div
                initial={{x: 300}}
                whileInView={{x: 0}}
                viewport={{ once: true }}
                className={classNames('container', styles.schedule__container)}
            >
                <Title>Расписание</Title>
                <div className={classNames(styles.schedule__menu)}>
                    {isMediumScreen ? <Carousel select={handleSelect} active={state}/> : <Menu select={handleSelect} active={state}/>}
                </div>
                <div className={classNames(styles.schedule__tables)}>
                    <Table schedule={state} />
                </div>
            </motion.div>
        </section>
    );
}

export default Schedule;
