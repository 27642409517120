import classNames from "classnames";
import Logotype from "../../../../shared/components/logotype";
import Navigation from "../../../../shared/components/navigation";

import background from "static/home-backround.jpg"
import styles from "./header.module.scss";
import CircleButton from "../../../../shared/blocks/circle-button";

function Header() {
    return (
        <header
            className={classNames(styles.header)}
            style={{backgroundImage: `url(${background})`}}
        >
            <div className={classNames('container', styles.header__container)}>
                <Logotype white/>
                <Navigation white/>
            </div>
            <section className={classNames('container')}>
                <div className={classNames(styles.header__content)}>
                    <div className={classNames(styles.header__text)}>
                        <h1 className={classNames(styles.header__title)}>
                            <span>Триумф</span> — это не просто спортивный клуб, это философия. Философия твоей жизни.
                        </h1>
                        <p className={classNames(styles.header__subTitle)}>
                            Уже более 10 лет мы воспитываем спортсменов на территории г.о. Красногорск.
                        </p>
                        <p className={classNames(styles.header__subTitle)}>
                            Наши дети участвуют на соревнованиях различного уровня и занимают призовые места,<br/>а также ежегодно принимают участие в аттестациях.
                        </p>
                    </div>
                    <div className={classNames(styles.header__button)}>
                        <CircleButton/>
                    </div>
                </div>
            </section>
        </header>
    );
}

export default Header;
