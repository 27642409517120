import React from "react";
import {motion} from "framer-motion";
import classNames from "classnames";

import styles from "../table.module.scss";


function PhysicalTrainingTable() {
    return (
        <motion.div
            className={classNames(styles.container)}
            initial={{y: -200}}
            animate={{y: 0}}
        >
            <table className={classNames(styles.table)}>
                <thead className={classNames(styles.table__thead)}>
                    <th className={classNames(styles.table__th, styles.table__th_gym)}>Зал «Микрогород в лесу»</th>
                    <th className={classNames(styles.table__th)}>Вторник/Четверг</th>
                </thead>
                <tbody>
                <tr>
                    <td className={classNames(styles.table__td)}>Младшая группа</td>
                    <td className={classNames(styles.table__td)}>17:30-18:30</td>
                </tr>
                <tr>
                    <td className={classNames(styles.table__td)}>Старшая группа</td>
                    <td className={classNames(styles.table__td)}>18:30-19:30</td>
                </tr>
                </tbody>
            </table>
        </motion.div>
    );
}

export default PhysicalTrainingTable;
