import classNames from "classnames";
import Title from "../../../../shared/components/title";

import trainer1 from "static/trainers/t1.jpg";
import trainer2 from "static/trainers/t2.jpg";
import trainer3 from "static/trainers/t3.jpg";
import trainer4 from "static/trainers/t4.jpg";
import styles from "./trainers.module.scss";

function Trainers() {
    return (
        <section className={classNames(styles.trainers)}>
            <div className={classNames('container')}>
                <Title white>Тренерский состав</Title>
            </div>
            <div className={classNames('container', styles.trainers__container)}>
                <div className={classNames(styles.trainers__card, styles.trainersCard)}>
                    <img src={trainer1} alt="Тренер"/>
                    <div className={classNames(styles.trainersCard__info)}>
                        <div className={classNames(styles.trainersCard__name)}>Антон</div>
                    </div>
                </div>
                <div className={classNames(styles.trainers__card, styles.trainersCard)}>
                    <img src={trainer2} alt="Тренер"/>
                    <div className={classNames(styles.trainersCard__info)}>
                        <div className={classNames(styles.trainersCard__name)}>Николай</div>
                    </div>
                </div>
                <div className={classNames(styles.trainers__card, styles.trainersCard)}>
                    <img src={trainer3} alt="Тренер"/>
                    <div className={classNames(styles.trainersCard__info)}>
                        <div className={classNames(styles.trainersCard__name)}>Агил</div>
                    </div>
                </div>
                <div className={classNames(styles.trainers__card, styles.trainersCard)}>
                    <img src={trainer4} alt="Тренер"/>
                    <div className={classNames(styles.trainersCard__info)}>
                        <div className={classNames(styles.trainersCard__name)}>Данила</div>
                    </div>
                </div>
            </div>
        </section>
    );
}



export default Trainers;
