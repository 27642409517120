import classNames from "classnames";

import styles from "./card.module.scss";

function Card({number, text, dark}) {
    return (
        <div className={classNames(styles.card, {[styles.card_dark]: dark})}>
            <div className={classNames(styles.card__number)}>{number}</div>
            <div className={classNames(styles.card__text)}>{text}</div>
            <div className={classNames(styles.card__background)}>{number}</div>
        </div>
    );
}

export default Card;
