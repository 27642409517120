import classNames from "classnames";
import {motion} from "framer-motion";
import Title from "../../components/title";
import SubTitle from "../../components/sub-title";
import Form from "./components/form";

import styles from "./application.module.scss";

function Application() {
    return (
        <section className={classNames(styles.application)} id={'application'}>
            <motion.div
                initial={{x: 300}}
                whileInView={{x: 0}}
                viewport={{ once: true }}
                className={classNames('container')}
            >
                <Title>Первое занятие - бесплатно!</Title>
                <SubTitle>Оставьте заявку сейчас, чтобы записаться<br/>на пробное занятие!</SubTitle>
                <div className={classNames(styles.application__form)}>
                    <Form/>
                </div>
            </motion.div>
        </section>
    );
}

export default Application;
