import classNames from "classnames";

import styles from "./copyright.module.scss";

function Copyright() {
    return (
        <div className={classNames(styles.copyright)}>
            Develop by <a  className={classNames(styles.copyright__link)} href="https://kirka.dev">kirka.dev</a>
        </div>
    );
}

export default Copyright;
