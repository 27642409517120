import classNames from "classnames";
import Logotype from "../../../../shared/components/logotype";
import Navigation from "../../../../shared/components/navigation";
import CircleButton from "../../../../shared/blocks/circle-button";

import styles from "./header.module.scss";

function Header() {
    return (
        <header
            className={classNames(styles.header)}
        >
            <div className={classNames('container', styles.header__container)}>
                <Logotype />
                <Navigation />
            </div>
            <section className={classNames('container')}>
                <div className={classNames(styles.header__content)}>
                    <div className={classNames(styles.header__text)}>
                        <h1 className={classNames(styles.header__title)}>
                            <span>Во́льная борьба́</span>
                        </h1>
                        <p className={classNames(styles.header__subTitle)}>
                            Спортивное единоборство и олимпийский вид спорта, заключающийся в поединке двух спортсменов по определённым правилам, с применением различных приёмов (захватов, бросков, переворотов, подсечек и т. п.), в котором каждый из соперников пытается положить другого на лопатки, либо набрать отрыв в 10 баллов и победить. В вольной борьбе, в отличие от греко-римской и борьбы на поясах, разрешены захваты ног противника, подсечки и активное использование ног при выполнении какого-либо приема.
                        </p>
                    </div>
                    <div className={classNames(styles.header__button)}>
                        <CircleButton white/>
                    </div>
                </div>
            </section>
        </header>
    );
}

export default Header;
