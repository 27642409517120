import sport from 'static/sport.jpg';
import kudo from 'static/images/kudo.jpg';
import jiuJitsu from 'static/images/jiu-jitsu.jpg';
import football from 'static/images/football.jpg';
import gymnastics from 'static/images/gymnastics.jpg';
import physicalTraining from 'static/images/physical-training.jpg';
import volleyball from 'static/images/volleyball.jpg';

export const CARDS = [
    {
        value: 'kudo',
        title: 'Кудо',
        image: kudo,
    },
    {
        value: 'jiu-jitsu',
        title: 'Вольная борьба',
        image: jiuJitsu,
    },
    {
        value: 'football',
        title: 'Футбол',
        image: football,
    },
    // {
    //     value: 'gymnastics',
    //     title: 'Спортивная гимнастика',
    //     image: gymnastics,
    // },
    {
        value: 'physical-training',
        title: 'Общая физическая подготовка',
        image: physicalTraining,
    },
    {
        value: 'volleyball',
        title: 'Волейбол',
        image: volleyball,
    }
]
