import React from "react";
import {motion} from "framer-motion";
import classNames from "classnames";

import styles from "../table.module.scss";


function JiuJitsuTable() {
    return (
        <motion.div
            className={classNames(styles.container)}
            initial={{y: -200}}
            animate={{y: 0}}
        >
            <table className={classNames(styles.table)}>
                <thead className={classNames(styles.table__thead)}>
                <th className={classNames(styles.table__th, styles.table__th_gym)}>Зал «Микрогород в лесу»</th>
                <th className={classNames(styles.table__th)}>Понедельник/Среда</th>
                <th className={classNames(styles.table__th)}>Пятница</th>
                </thead>
                <tbody>
                <tr>
                    <td className={classNames(styles.table__td)}>Младшая группа <span>Идет набор</span></td>
                    <td className={classNames(styles.table__td)}>18:00 - 19:00</td>
                    <td className={classNames(styles.table__td)}>17:00 - 18:00</td>
                </tr>
                </tbody>
            </table>
            <table className={classNames(styles.table)}>
                <thead className={classNames(styles.table__thead)}>
                <th className={classNames(styles.table__th, styles.table__th_gym)}></th>
                <th className={classNames(styles.table__th)}>Вторник/четверг</th>
                </thead>
                <tbody>
                <tr>
                    <td className={classNames(styles.table__td)}>Младшая группа</td>
                    <td className={classNames(styles.table__td)}>17:00 - 18:00</td>
                </tr>
                </tbody>
            </table>
        </motion.div>
    );
}

export default JiuJitsuTable;
