import classNames from "classnames";

import styles from "./address.module.scss";

function Address({title, link, children}) {
    return (
        <div className={classNames(styles.address)}>
            <h4 className={classNames(styles.address__title)}>{title}</h4>
            <a className={classNames(styles.address__link)} href={link}>{children}</a>
        </div>
    );
}

export default Address;
