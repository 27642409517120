import classNames from "classnames";
import {forwardRef} from "react";
import {useNavigate} from "react-router-dom";
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import Card from "../card";
import {CARDS} from "./carousel.const";

import styles from "./carousel.module.scss";

function Carousel({}, ref) {
    const navigate = useNavigate();

    return (
        <div className={classNames(styles.carousel)}>
            <Swiper
                ref={ref}
                slidesPerView={'auto'}
                spaceBetween={30}
                style={{paddingRight: '30px'}}
                modules={[Navigation]}
                rewind={true}
            >
                {CARDS.map((item, index) => {
                    return (
                        <SwiperSlide style={{width: '200px'}}>
                            <Card title={item.title} image={item.image} click={() => navigate(item.value)} key={index} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    );
}

export default forwardRef(Carousel);
