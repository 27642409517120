import classNames from "classnames";
import {motion} from "framer-motion";
import {useState} from "react";

import styles from "./form.module.scss";

function Form() {
    const [submit, setSubmit] = useState(false);

    function handleSubmit(e) {
        fetch('/send-application/',{
            headers: new Headers(),
            method: 'POST',
            body: new FormData(e.target),
        }).then((res) => {
            if (res.ok) {
                setSubmit(true);
            }
        });

        e.preventDefault();
    }

    return (
        <form className={classNames(styles.form)} onSubmit={(e) => handleSubmit(e)}>
            <input className={classNames(styles.form__field)} type="text" name="name" id="name" placeholder='Имя'/>
            <input className={classNames(styles.form__field)} type="text" name="telephone" id="telephone" placeholder='Телефон'/>
            <input className={classNames(styles.form__field)} type="text" name="email" id="email" placeholder='E-Mail'/>
            <div className={classNames(styles.form__selector)}>
                <input className={classNames(styles.form__field)} type="text" name="sport" id="sport" placeholder='Направление'/>
            </div>
            <motion.button
                className={classNames(styles.form__button)}
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
                disabled={submit}
            >Отправить</motion.button>
        </form>
    );
}



export default Form;
