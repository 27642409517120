import classNames from "classnames";

import styles from "./card.module.scss";

function Card({title, image, click, key}) {
    return (
        <div className={classNames(styles.card)} onClick={() => click()} key={key}>
            <div className={classNames(styles.card__image)}>
                <img src={image}/>
                <button className={classNames(styles.card__button)}>Подробнее</button>
            </div>
            <div className={classNames(styles.card__title)}>{title}</div>
        </div>
    );
}

export default Card;
