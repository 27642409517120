import classNames from "classnames";
import Marquee from "react-fast-marquee";

import styles from "./line.module.scss";

function Line() {
    return (
        <Marquee
            className={classNames(styles.line)}
            loop={0}
            speed={70}
            gradient={false}
        >
            <p className={classNames(styles.line__item)}>Ежегодная аттестация</p>
            <p className={classNames(styles.line__item)}>4 зала на территории Московской области</p>
            <p className={classNames(styles.line__item)}>Выезжаем на соревнования разного уровня</p>
            <p className={classNames(styles.line__item)}>работаем по собственной разработанной программе</p>
            <p className={classNames(styles.line__item)}>максимум экипировки для защиты ребенка</p>
            <p className={classNames(styles.line__item)}>Работаем с заключением договора</p>
        </Marquee>
    );
}



export default Line;
