import classNames from "classnames";
import Title from "../../../../shared/components/title";

import trainer from "static/trainer.jpg";
import styles from "./trainers.module.scss";

function Trainers() {
    return (
        <section className={classNames(styles.trainers)}>
            <div className={classNames('container')}>
                <Title white>Тренерский состав</Title>
            </div>
            <div className={classNames('container', styles.trainers__container)}>
                <div className={classNames(styles.trainers__card, styles.trainersCard)}>
                    <img src={trainer} alt="Тренер"/>
                    <div className={classNames(styles.trainersCard__info)}>
                        <div className={classNames(styles.trainersCard__name)}>Налимова Регина</div>
                        <ul className={classNames(styles.trainersCard__list)}>
                            <li>Неоднократный победитель Первенства Республики Хакасия по вольной (женской) борьбе среди юниоров и женщин</li>
                            <li>Победитель Первенства СФО по вольной борьбе среди юниоров</li>
                            <li>Призёр Первенства СФО по вольной борьбе среди юниоров (г. Улан-Удэ, г. Назарово)</li>
                            <li>Неоднократный призёр Всероссийского турнира по вольной борьбе на призы Академии борьбы им. Д.Г.Миндиашвилли среди женщин</li>
                            <li>Серебряный призёр Всероссийского турнира на призы ЗМС Анны Шамовой по вольной борьбе среди женщин (г. Орехово- Зуево)</li>
                            <li>Призёр различных других Всероссийских соревнований и Первенств</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Trainers;
