import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

function Layout({children}) {
    const location = useLocation();

    useEffect(() => {
        const { hash } = window.location;
        if (hash.length) {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) element.scrollIntoView({behavior: "smooth", block: "center"});
            }, 0);
        } else {
            window.scrollTo({top: 0, behavior: "smooth"});
        }
    }, [location]);

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
}

export default Layout;
