import React from 'react';
import Header from "./blocks/header";
import Sports from "../../shared/blocks/sports";
import Schedule from "./blocks/schedule";
import Line from "../../shared/blocks/line";
import Application from "../../shared/blocks/application";
import News from "../../shared/blocks/news";
import Addresses from "../../shared/blocks/addresses";
import Benefits from "../../shared/blocks/benefits";
import Footer from "../../shared/blocks/footer";

function Home() {
    return (
        <React.Fragment>
            <Header/>
            <main>
                <Sports/>
                <Schedule/>
                <Line/>
                <Application/>
                <News/>
                <Addresses/>
                <Line/>
                <Benefits/>
            </main>
            <Footer/>
        </React.Fragment>
    );
}

export default Home;
