import classNames from "classnames";
import Title from "../../../../shared/components/title";

import trainer from "static/trainer.jpg";
import styles from "./trainers.module.scss";

function Trainers() {
    return (
        <section className={classNames(styles.trainers)}>
            <div className={classNames('container')}>
                <Title white>Тренерский состав</Title>
            </div>
            <div className={classNames('container', styles.trainers__container)}>
                <div className={classNames(styles.trainers__card, styles.trainersCard)}>
                    <img src={trainer} alt="Тренер"/>
                    <div className={classNames(styles.trainersCard__info)}>
                        <div className={classNames(styles.trainersCard__name)}>Войцеховская Яна</div>
                        <ul className={classNames(styles.trainersCard__list)}>
                            <li>Тренер - преподаватель с педагогическим образованием</li>
                            <li>Студентка 3 курса РГУФК направление АФВ</li>
                            <li>Обладатель чёрного пояса 3дан по каратэ стиль ШОТОКАН</li>
                            <li>Неоднократная победительница и призёр городских и региональных соревнований</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}



export default Trainers;
