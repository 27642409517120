import classNames from "classnames";
import {Link} from "react-router-dom";

import styles from "./navigation.module.scss";
import {NAVIGATION} from "../../consts/navigation.const";

function Navigation({white}) {
    return (
        <nav className={classNames(styles.navigation, {[styles.navigation_white]: white})}>
            {NAVIGATION.map((item, index) =>
                <Link to={item.url} className={classNames(styles.navigation__link)} key={index}>{item.title}</Link>
            )}
        </nav>
    );
}

export default Navigation;
