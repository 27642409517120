import classNames from "classnames";
import {Link} from "react-router-dom";
import logotype from "./../../../static/logotype.svg";
import logotypeDark from "./../../../static/logotype-dark.svg";

import styles from "./logotype.module.scss";

function Logotype({white}) {
    return (
        <Link to="/" className={classNames(styles.logotype, {[styles.logotype_white]: white})}>
            <img src={white ? logotype : logotypeDark} alt=""/>
        </Link>
    );
}

export default Logotype;
