import classNames from "classnames";

import styles from "./card.module.scss";

function Card({title, image, category, key}) {
    return (
        <div className={classNames(styles.card)} key={key}>
            <div className={classNames(styles.card__image)}>
                <img src={image}/>
            </div>
            <div className={classNames(styles.card__category)}>{category}</div>
            <p className={classNames(styles.card__title)}>{title}</p>
        </div>
    );
}

export default Card;
