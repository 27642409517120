import React from 'react';
import Header from "./components/header";
import Trainers from "./components/trainers";
import Schedule from "../../shared/blocks/schedule";
import Line from "../../shared/blocks/line";
import Application from "../../shared/blocks/application";
import Benefits from "../../shared/blocks/benefits";
import Addresses from "./components/addresses";
import Footer from "../../shared/blocks/footer";

function Football() {
    return (
        <React.Fragment>
            <Header/>
            <main>
                <Trainers/>
                <Schedule schedule={'football'}/>
                <Line/>
                <Application/>
                <Benefits dark/>
                <Addresses/>
            </main>
            <Footer/>
        </React.Fragment>
    );
}

export default Football;
