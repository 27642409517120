import classNames from "classnames";
import {motion} from "framer-motion";
import Title from "../../components/title";

import styles from "./addresses.module.scss";
import Address from "./components/card";

function Addresses() {
    return (
        <section className={classNames(styles.addresses)} id={'addresses'}>
            <motion.div
                initial={{x: 300}}
                whileInView={{x: 0}}
                viewport={{ once: true }}
                className={classNames('container', styles.addresses__container)}
            >
                <Title>Наши адреса</Title>
                <div className={classNames(styles.addresses__list)}>
                    <Address
                        title={'«Опалиха»'}
                        link={'https://yandex.ru/maps/-/CCURbGRQdB'}
                    >
                        Красногорск, мкр Опалиха,<br/>Есенинская ул., 13
                        <span>Кудо</span>
                    </Address>
                    <Address
                        title={'«Микрогород в лесу»'}
                        link={'https://yandex.ru/maps/-/CCURbGRJSD'}
                    >
                        Красногорск, п. Отрадное,<br/>ЖК Микрогород в Лесу, к14А
                        <span>Кудо/Футбол/Борьба/ОФП/Волейбол</span>
                    </Address>
                    <Address
                        title={'«Изумрудные холмы»'}
                        link={'https://yandex.ru/maps/-/CCURbGRfPA'}
                    >
                        Красногорск, Светлая улица, 3Ас5,<br/>Face to Face fitness
                        <span>Кудо</span>
                    </Address>
                    <Address
                        title={'«Павшинская пойма»'}
                        link={'https://yandex.ru/maps/-/CCURbGR9XA'}
                    >
                        Красногорск, ул. Игната Титова, 5
                        <span>Кудо</span>
                    </Address>
                    <Address
                        title={'«Митино»'}
                        link={'https://yandex.ru/maps/-/CCURrYt4cD'}
                    >
                        г. Москва, ул. Митинская, д.28А
                        <span>Футбол</span>
                    </Address>
                </div>
                <div className={classNames(styles.addresses__map)}>
                    <iframe
                        src="https://yandex.ru/map-widget/v1/?um=constructor%3A478bb7b24810d3e9eedb5b4d65cda1401233acf512da9ee69dae3e7f095fb9af&amp;source=constructor"
                        width="100%" frameBorder="0">
                    </iframe>
                </div>
            </motion.div>
        </section>
    );
}

export default Addresses;
