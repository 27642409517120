import classNames from "classnames";
import {Swiper, SwiperSlide} from "swiper/react";
import Card from "../card";
import {NEWS} from "./carousel.const";

import styles from "./carousel.module.scss";

function Carousel() {
    return (
        <div className={classNames(styles.carousel)}>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={30}
                style={{paddingRight: '30px'}}
            >
                {NEWS.map((item, index) => {
                    return (
                        <SwiperSlide style={{width: '400px'}}>
                            <Card title={item.title} image={item.image} category={item.category} key={index} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    );
}

export default Carousel;
