import FootballTable from "./football";
import GymnasticsTable from "./gymnastics";
import KudoTable from "./kudo";
import PhysicalTrainingTable from "./physical-training";
import VolleyballTable from "./volleyball";
import JiuJitsuTable from "./jiu-jitsu";

function Table({schedule}) {
    return (
        {
            'football': <FootballTable/>,
            'gymnastics': <GymnasticsTable/>,
            'kudo': <KudoTable/>,
            'physical-training': <PhysicalTrainingTable/>,
            'volleyball': <VolleyballTable/>,
            'jiu-jitsu': <JiuJitsuTable/>
        }[schedule]
    )
}

export default Table;
