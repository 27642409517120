export const NAVIGATION = [
    {
        url: '/#sports',
        title: 'Направления'
    },
    {
        url: '#schedule',
        title: 'Расписание'
    },
    {
        url: '#addresses',
        title: 'Адреса'
    },
    {
        url: '/#news',
        title: 'Новости'
    },
]
