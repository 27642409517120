import classNames from "classnames";
import React, {useMemo} from 'react';
import Title from "../../components/title";
import Carousel from "./components/carousel";

import styles from "./sports.module.scss";

function Sports() {
    const sliderRef = useMemo(() => React.createRef(), []);

    function handleClickNext() {
        sliderRef.current.swiper.slideNext();
    };

    return (
        <section className={classNames(styles.sports)} id={'sports'}>
            <div className={classNames('container-fluid')}>
                <Carousel ref={sliderRef} />
            </div>
            <div className={classNames('container', styles.sports__container)}>
                <Title white>Наш спортивный клуб имеет шесть направлений</Title>
                <button className={classNames(styles.sports__nextButton)} onClick={handleClickNext}></button>
            </div>
        </section>
    );
}

export default Sports;
