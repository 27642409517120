import classNames from "classnames";
import {motion} from "framer-motion";
import Card from "./coponents/card";
import Title from "../../components/title";

import styles from "./benefits.module.scss";

function Benefits({dark}) {
    return (
        <section className={classNames(styles.benefits, {[styles.benefits_dark]: dark})}>
            <motion.div
                initial={{x: 300}}
                whileInView={{x: 0}}
                viewport={{ once: true }}
                className={classNames('container', styles.benefits__container)}
            >
                <div className={classNames(styles.benefits__cards)}>
                    <Card number={'10'} text={'лет опыта'} dark={dark} />
                    <Card number={'+300'} text={'довольных учеников'} dark={dark} />
                    <Card number={'76'} text={'призовых мест'} dark={dark} />
                    <Card number={'4'} text={'зала'} dark={dark} />
                </div>
                <Title white={dark}>Запишись сегодня,<br/>первое занятие бесплатно!</Title>
            </motion.div>
        </section>
    );
}

export default Benefits;
