import classNames from "classnames";

import styles from "./contacts.module.scss";

function Contacts() {
    const contactItems = [
        {
            url: 'mailto:triumph-team@yandex.ru',
            title: 'triumph-team@yandex.ru'
        },
        {
            url: 'tel:+79168777779',
            title: '+7 (916) 877-77-79'
        }
    ]

    return (
        <div className={classNames(styles.contacts)}>
            {contactItems.map(item =>
                <a className={classNames(styles.contacts__link)} href={item.url}>{item.title}</a>
            )}
        </div>
    );
}

export default Contacts;
