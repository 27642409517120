import classNames from "classnames";

import {MENU} from "./menu.const";
import styles from "./menu.module.scss";;

function Menu({select, active}) {
    return (
        <div className={classNames(styles.menu)}>
            {
                MENU.map(item =>
                    <button
                        onClick={() => select(item.value)}
                        className={classNames(styles.menu__button, {[styles.menu__button_active]: item.value === active})}
                    >
                        {item.title}
                    </button>
                )
            }
        </div>
    );
}



export default Menu;
