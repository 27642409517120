import news from 'static/news.jpg';
import post1 from 'static/posts/post_1.jpg';
import post2 from 'static/posts/post_2.jpg';
import post3 from 'static/posts/post_3.jpg';
import post4 from 'static/posts/post_4.jpg';

export const NEWS = [
    {
        image: post4,
        title: 'Объявляется набор в секции по всем видам спорта по всем локациям!',
        category: 'События',
    },
    {
        image: post1,
        title: 'С Днём знаний! Желаем Вам успешного учебного года без трудностей и лени, чтобы каждый день был наполнен интересом и весельем!',
        category: 'События',
    },
    {
        image: post2,
        title: 'Объявляется набор в секции КУДО Детских групп (4-6 лет) по всем локациям!',
        category: 'Кудо',
    },
]
