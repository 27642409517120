import classNames from "classnames";
import {motion} from "framer-motion";
import Title from "../../../../shared/components/title";

import styles from "./addresses.module.scss";
import Address from "./components/card";

function Addresses() {
    return (
        <section className={classNames(styles.addresses)} id={'addresses'}>
            <motion.div
                initial={{x: 300}}
                whileInView={{x: 0}}
                viewport={{ once: true }}
                className={classNames('container', styles.addresses__container)}
            >
                <Title>Наши адреса</Title>
                <div className={classNames(styles.addresses__list)}>
                    <Address
                        title={'«Микрогород в лесу»'}
                        link={'https://yandex.ru/maps/-/CCURbGRJSD'}
                    >
                        Красногорск, п. Отрадное,<br/>ЖК Микрогород в Лесу, к14А
                    </Address>
                    <Address
                        title={'«Митино»'}
                        link={'https://yandex.ru/maps/-/CCURrYt4cD'}
                    >
                        г. Москва, ул. Митинская, д.28А
                    </Address>
                </div>
                <div className={classNames(styles.addresses__map)}>
                    <iframe
                        src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa658a9b16ae2ae8dbbe0b76244a1df65b10db3206e8792dc5ee0bb560b6dc791&amp;source=constructor"
                        width="100%" frameBorder="0">
                    </iframe>
                </div>
            </motion.div>
        </section>
    );
}

export default Addresses;
