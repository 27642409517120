import React from "react";
import {motion} from "framer-motion";
import classNames from "classnames";

import styles from "../table.module.scss";

function KudoTable() {
    return (
        <motion.div
            className={classNames(styles.container)}
            initial={{y: -200}}
            animate={{y: 0}}
        >
            <table className={classNames(styles.table)}>
                <thead className={classNames(styles.table__thead)}>
                    <tr>
                        <th className={classNames(styles.table__th, styles.table__th_gym)}>Зал «Опалиха»</th>
                        <th className={classNames(styles.table__th)}>Вторник/Четверг</th>
                        <th className={classNames(styles.table__th)}>Суббота</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={classNames(styles.table__td)}>Детская группа (4-6 лет) <span>Идет набор</span></td>
                    <td className={classNames(styles.table__td)}>17:15 - 18:00</td>
                </tr>
                <tr>
                    <td className={classNames(styles.table__td)}>Младшая группа (7-9 лет)</td>
                    <td className={classNames(styles.table__td)}>18:00 - 19:00</td>
                    <td className={classNames(styles.table__td)}>13:00 - 14:30</td>
                </tr>
                <tr>
                    <td className={classNames(styles.table__td)}>Старшая группа (10-15 лет)</td>
                    <td className={classNames(styles.table__td)}>19:00 - 20:20</td>
                    <td className={classNames(styles.table__td)}>13:00 - 14:30</td>
                </tr>
                <tr>
                    <td className={classNames(styles.table__td)}>MMA (14+)</td>
                    <td className={classNames(styles.table__td)}>20:30 - 22:00</td>
                    <td className={classNames(styles.table__td)}>11:30 - 13:00</td>
                </tr>
                </tbody>
            </table>
            <table className={classNames(styles.table)}>
                <thead className={classNames(styles.table__thead)}>
                <th className={classNames(styles.table__th, styles.table__th_gym)}>Зал «Микрогород в лесу»</th>
                <th className={classNames(styles.table__th)}>Вторник/Четверг</th>
                <th className={classNames(styles.table__th)}>Суббота</th>
                </thead>
                <tbody>
                <tr>
                    <td className={classNames(styles.table__td)}>Младшая группа (7-10 лет)</td>
                    <td className={classNames(styles.table__td)}>18:00 - 19:00</td>
                    <td className={classNames(styles.table__td)}>10:00 - 11:30</td>
                </tr>
                <tr>
                    <td className={classNames(styles.table__td)}>Старшая группа (11-16 лет)</td>
                    <td className={classNames(styles.table__td)}>19:00 - 20:30</td>
                    <td className={classNames(styles.table__td)}>10:00 - 11:30</td>
                </tr>
                </tbody>
            </table>
            <table className={classNames(styles.table)}>
                <thead className={classNames(styles.table__thead)}>
                <th className={classNames(styles.table__th, styles.table__th_gym)}>Зал «Изумрудные холмы»</th>
                <th className={classNames(styles.table__th)}>Понедельник/Среда/Пятница</th>
                </thead>
                <tbody>
                <tr>
                    <td className={classNames(styles.table__td)}>Детская группа (4-6 лет) <span>Идет набор</span></td>
                    <td className={classNames(styles.table__td)}>17:45 - 18:30</td>
                </tr>
                <tr>
                    <td className={classNames(styles.table__td)}>Младшая группа (7-9 лет)</td>
                    <td className={classNames(styles.table__td)}>18:30 - 19:30</td>
                </tr>
                <tr>
                    <td className={classNames(styles.table__td)}>Старшая группа (10-15 лет)</td>
                    <td className={classNames(styles.table__td)}>19:30 - 21:00</td>
                </tr>
                </tbody>
            </table>
            <table className={classNames(styles.table)}>
                <thead className={classNames(styles.table__thead)}>
                <th className={classNames(styles.table__th, styles.table__th_gym)}>Зал «Павшинская пойма»</th>
                <th className={classNames(styles.table__th)}>Понедельник/Среда/Пятница</th>
                </thead>
                <tbody>
                <tr>
                    <td className={classNames(styles.table__td)}>Детская группа (4-6 лет) <span>Идет набор</span></td>
                    <td className={classNames(styles.table__td)}>17:15 - 18:00</td>
                </tr>
                <tr>
                    <td className={classNames(styles.table__td)}>Младшая группа (7-9 лет)</td>
                    <td className={classNames(styles.table__td)}>18:00 - 19:00</td>
                </tr>
                <tr>
                    <td className={classNames(styles.table__td)}>Старшая группа (10-15 лет)</td>
                    <td className={classNames(styles.table__td)}>19:00 - 20:30</td>
                </tr>
                </tbody>
            </table>
        </motion.div>
    );
}

export default KudoTable;
