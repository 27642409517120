import classNames from "classnames";
import Logotype from "../../../../shared/components/logotype";
import Navigation from "../../../../shared/components/navigation";
import CircleButton from "../../../../shared/blocks/circle-button";

import styles from "./header.module.scss";

function Header() {
    return (
        <header
            className={classNames(styles.header)}
        >
            <div className={classNames('container', styles.header__container)}>
                <Logotype />
                <Navigation />
            </div>
            <section className={classNames('container')}>
                <div className={classNames(styles.header__content)}>
                    <div className={classNames(styles.header__text)}>
                        <h1 className={classNames(styles.header__title)}>
                            <span>Футбол</span> — это командный вид спорта, благодаря ему дети налаживают коммуникацию
                        </h1>
                        <p className={classNames(styles.header__subTitle)}>
                            Футбол укрепляет здоровье, повышает силу и выносливость организма, он вырабатывает концентрацию и эмоционально разгружает ребенка.
                        </p>
                        <p className={classNames(styles.header__subTitle)}>
                            В настоящее время один из самых популярных видов и массовых видов спорта.
                        </p>
                    </div>
                    <div className={classNames(styles.header__button)}>
                        <CircleButton white/>
                    </div>
                </div>
            </section>
        </header>
    );
}

export default Header;
