import classNames from "classnames";
import {Swiper, SwiperSlide} from "swiper/react";
import {MENU} from "./menu.const";


import styles from "./carousel.module.scss";

function Carousel({select, active}) {
    return (
        <div className={classNames(styles.carousel)}>
            <Swiper
                slidesPerView={'auto'}
                loop={true}
            >
                {
                    MENU.map(item =>
                        <SwiperSlide style={{width: 'auto'}}>
                            <button
                                onClick={() => select(item.value)}
                                className={classNames(styles.carousel__button, {[styles.carousel__button_active]: item.value === active})}
                            >
                                {item.title}
                            </button>
                        </SwiperSlide>
                    )
                }
            </Swiper>
        </div>
    )
}

export default Carousel;
