import classNames from "classnames";
import {Link} from "react-router-dom";

import styles from "./navigation.module.scss";
import {NAVIGATION} from "../../../../consts/navigation.const";

function Navigation() {
    return (
        <nav className={classNames(styles.navigation)}>
            {NAVIGATION.map(item =>
                <Link to={item.url} className={classNames(styles.navigation__link)}>{item.title}</Link>
            )}
        </nav>
    );
}

export default Navigation;
