export const MENU = [
    {
        value: 'kudo',
        title: 'Кудо',
    },
    {
        value: 'jiu-jitsu',
        title: 'Вольная борьба',
    },
    {
        value: 'football',
        title: 'Футбол',
    },
    // {
    //     value: 'gymnastics',
    //     title: 'Спортивная гимнастика',
    // },
    {
        value: 'physical-training',
        title: 'Общая физическая подготовка',
    },
    {
        value: 'volleyball',
        title: 'Волейбол',
    }
]
