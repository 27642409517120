import classNames from "classnames";
import Title from "../../components/title";
import Carousel from "./components/carousel";

import styles from "./news.module.scss";

function News() {
    return (
        <section className={classNames(styles.news)} id={'news'}>
            <div className={classNames('container-fluid')}>
                <Carousel/>
            </div>
            <div className={classNames('container')}>
                <Title white>Новости нашего клуба</Title>
            </div>
        </section>
    );
}

export default News;
