import classNames from "classnames";

import styles from "./sub-title.module.scss";

function Title({children, white}) {
    return (
        <h3 className={classNames(styles.subTitle, {[styles.subTitle_white]: white})}>
            {children}
        </h3>
    );
}

export default Title;
