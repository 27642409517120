import classNames from "classnames";
import {Link} from "react-router-dom";
import circle from "static/circle.svg";
import circleDark from "static/circle_dark.svg";

import styles from "./circle-button.module.scss";

function CircleButton({white}) {
    return (
        <div className={classNames(styles.circleButton, {[styles.circleButton_white]: white})}>
            <Link className={classNames(styles.circleButton__link)} to={'#application'}>Записаться</Link>
            <img className={classNames(styles.circleButton__circle)} src={white ? circleDark : circle} alt="Первое занятие бесплатно"/>
        </div>
    );
}



export default CircleButton;
