import classNames from "classnames";
import Title from "../../../../shared/components/title";

import trainer from "static/trainer.jpg";
import styles from "./trainers.module.scss";

function Trainers() {
    return (
        <section className={classNames(styles.trainers)}>
            <div className={classNames('container')}>
                <Title white>Тренерский состав</Title>
            </div>
            <div className={classNames('container', styles.trainers__container)}>
                <div className={classNames(styles.trainers__card, styles.trainersCard)}>
                    <img src={trainer} alt="Тренер"/>
                    <div className={classNames(styles.trainersCard__info)}>
                        <div className={classNames(styles.trainersCard__name)}>Читайкин Илья</div>
                        <ul className={classNames(styles.trainersCard__list)}>
                            <li>Кандидат в мастера спорта по волейболу</li>
                            <li>Личное участие в соревнованиях Чемпионата России по Волейболу среди мужских команд высшей лиги</li>
                            <li>Победитель и призер Чемпионатов России по Волейболу высшей лиги (2013-2016)</li>
                            <li>Победитель чемпионата Московской области по волейболу</li>
                            <li>Участие в качестве играющего тренера в Чемпионате Московской области по волейболу (2018)</li>
                            <li>Тренер команды по волейболу СК «Зоркий» г. Красногорск (2017-2018)</li>
                        </ul>
                        <p className={classNames(styles.trainersCard__text)}>
                            Опыт в организации и проведения спортивно массовых мероприятий по волейболу, судейство на соревнованиях по волейболу
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}




export default Trainers;
