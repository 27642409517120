import classNames from "classnames";
import {motion} from "framer-motion";
import Title from "../../components/title";
import Table from "../../components/table";

import styles from "./schedule.module.scss";

function Schedule({schedule}) {
    return (
        <section className={classNames(styles.schedule)} id={'schedule'}>
            <motion.div
                initial={{x: 300}}
                whileInView={{x: 0}}
                viewport={{ once: true }}
                className={classNames('container', styles.schedule__container)}
            >
                <Title>Расписание</Title>
                <div className={classNames(styles.schedule__tables)}>
                    <Table schedule={schedule}/>
                </div>
            </motion.div>
        </section>
    );
}

export default Schedule;
