import classNames from "classnames";
import Title from "../../../../shared/components/title";

import trainer from "static/trainer.jpg";
import styles from "./trainers.module.scss";

function Trainers() {
    return (
        <section className={classNames(styles.trainers)}>
            <div className={classNames('container')}>
                <Title white>Тренерский состав</Title>
            </div>
            <div className={classNames('container', styles.trainers__container)}>
                <div className={classNames(styles.trainers__card, styles.trainersCard)}>
                    <img src={trainer} alt="Тренер"/>
                    <div className={classNames(styles.trainersCard__info)}>
                        <div className={classNames(styles.trainersCard__name)}>Попов Артём</div>
                        <ul className={classNames(styles.trainersCard__list)}>
                            <li>Игровая деятельность в футбольных клубах «САТУРН» и «КАМАЗ»</li>
                            <li>Чемпион республики Татарстан в составе футбольного клуба «КАМАЗ»</li>
                            <li>Призёр Межрегиональной Футбольного Лиги.</li>
                        </ul>
                        <p className={classNames(styles.trainersCard__text)}>
                            Опыт работы 7 лет, высшее образование на факультете «Теория и методика футбола»
                        </p>
                    </div>
                </div>
                <div className={classNames(styles.trainers__card, styles.trainersCard)}>
                    <img src={trainer} alt="Тренер"/>
                    <div className={classNames(styles.trainersCard__info)}>
                        <div className={classNames(styles.trainersCard__name)}>Шашкин Илья</div>
                        <ul className={classNames(styles.trainersCard__list)}>
                            <li>Имеется тренерская лицензия категории</li>
                            <li>Четырёхкратный чемпион России в составе юношеского Спартака в первенстве России</li>
                            <li>Двухкратный победитель Кубка России среди юношеских команд первенства России в составе юношеского Спартака</li>
                            <li>Двухкратный победитель кубка Первенства Москвы первенства Москвы</li>
                            <li>Действующий тренер ЛФЛ</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Trainers;
