import classNames from "classnames";
import Logotype from "../../components/logotype";
import Contacts from "./components/contacts";
import Navigation from "./components/navigation";

import styles from "./footer.module.scss";
import Copyright from "./components/copyright";

function Footer() {
    return (
        <footer className={classNames(styles.footer)}>
            <div className={classNames('container', styles.footer__container)}>
                <Logotype white />
                <div className={classNames(styles.footer__navigation)}>
                    <Navigation />
                </div>
            </div>
            <div className={classNames('container', styles.footer__container)}>
                <Copyright />
                <div className={classNames(styles.footer__contacts)}>
                    <Contacts />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
